.changing-font-container {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 80vh;

  --starting-color: var(--bg-primary);
  --ending-color: var(--bg-secondary);

  background: linear-gradient(
    var(--starting-color) 0%,
    var(--ending-color) 100%
  );


  * {
    --thumb-color: var(--thumb-color);
  }
}

@media (min-width: 1024px) {
  .changing-font-container {
    height: 100vh;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .changing-font-container {
    height: auto;
  }
}