.image-texts-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 100vh;
	overflow-x: hidden;
}

.image-text-container {
	height: 100%;
	max-width: 800px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.image-texts-container.mobile {
	flex-direction: column;
	height: auto;
}

.image-texts-container.mobile .image-text-container {
	width: 100%;
	max-width: 100%;
}

@media (min-width: 720px) {
	.image-texts-container {
		height: 100vh;
	}
}

@media (max-width: 720px) {
	.image-text-container {
		width: 100% !important;
	}
}

@media (min-width: 1024px) {
	.image-texts-container {
		height: 100%;
	}
}
