.main-page {
  width: 90vw;
  margin: 0 auto;
  overflow: hidden;
}

.panels-container {
  width: 100%; /* Adjusted for 90vw (100/90 * 100) */
  display: flex;
  transform: translateX(0);
  left: 10vw !important;
}

.panel {
  width: 90vw;
  height: 100vh;
  overflow: hidden !important;
  flex-shrink: 0;
}

.main-page .slider {
  position: fixed;
  bottom: 0px;
  left: 10vw;
  height: 10px;
  background-color: var(--progress-bar-color);
  transition: width 0.1s ease;
}

@media (min-width: 1024px) {
}