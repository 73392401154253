.block {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.block-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.block-title {
  width: 100%;
  height: 30%;
}

.block-title h2 {
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 700;
  color: var(--font-color-primary);
  font-family: var(--font-primary);
}

.block-description {
  width: 100%;
  margin-top: 1rem;
}

.block-description p {
  font-size: 1.2rem;
  font-weight: 300;
  color: var(--font-color-secondary);
  font-family: var(--font-secondary);
}

/* Media Queries */
@media (min-width: 1024px) {
  .block {
    height: 100%;
    padding: 1vh;
  }
  .block-title h2 {
    font-size: 2vh; 
  }
  .block-description {
    margin-top: 2rem;
  }
  .block-description p {
    line-height: 1.4;
    width: 100%;
    font-size: 1.8vh;
  }
}

@media screen and (min-width: 1400px) and (orientation: landscape) {
  .block {
    height: 100%;
    padding: 1vh;
  }
  .block-title h2 {
    font-size: 2.5vh; 
  }
  .block-description p {
    font-size: 2vh;
  }
}

@media (max-width: 768px) {
  .block {
    padding-left: 10px;
    padding-right: 10px;
  }
  .block-title h2 {
    font-size: 1.5rem;
  }
  .block-description p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .block {
    padding: 5px;
  }
  .block-title h2 {
    font-size: 1.2rem;
  }
  .block-description p {
    font-size: 0.8rem;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .block-description p {
    font-size: 1.5vh;
  }
}