.mobile-landing-panel {
    position: relative;
    padding: 20px;
	min-height: 40vh;
	background-color: var(--bg-primary);
}

.mobile-landing-panel-title {
    position: absolute;
    top: 0;
    left: 2vw;
    font-size: 2rem;
    font-family: var(--font-primary);
    color: var(--font-color-primary);
    font-weight: 700;
}

.mobile-landing-panel-subtitle {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-left: 2vw;
    padding-right: 2vw;
    font-size: 1.2rem;
    line-height: 1.7rem;
    font-family: var(--font-secondary);
    font-weight: 200;
    color: var(--font-color-secondary);
}

@media (orientation: landscape) {
	.mobile-landing-panel {
		min-height: 90vh;
	}

	.mobile-landing-panel-title {
		font-size: 3rem;
	}

	.mobile-landing-panel-subtitle {
		font-size: 2rem;
		line-height: 3rem;
	}
}