.image-text {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin: 0 auto;
	padding: 3%;

	background-color: var(--bg-primary);
	height: 100%;

	transition: background-color 0.3s ease;

	.text-container {
		width: 100%;
		height: 50%;
		display: flex;
		margin-top: 5vh;
		flex-direction: column;
		justify-content: baseline;
		align-items: center;
	}

}

.image-text.hovered {
	background-color: var(--bg-secondary);
}

.image-container {
	width: 100%;
	min-height: 50%;
	max-height: 50vh;
	height: fit-content;
	position: relative;
	overflow: hidden;
	border-radius: 10px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);


}

.image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transform: scale(1.1);
	transition: transform 1s ease, filter 1s ease;
	filter: brightness(0.9);
}
.image.hovered {
	transform: scale(1.14) !important;
	filter: brightness(1.1);
}
.image.zoomed-out {
	transform: scale(1);
}

.title {
	margin-top: 20px;
	font-size: 1.5rem;
	width: 100%;

	font-weight: 600;
	font-family: var(--font-primary);
	color: var(--font-color-primary);
}

.subtitle {
	position: absolute;
	bottom: 0;
	left: 0;
	font-size: 1rem;
	font-weight: 300;
	font-family: var(--font-secondary);
	color: var(--font-color-secondary);
}
.text {
	margin-top: 10px;
	font-size: 1rem;
	font-weight: 300;
	font-family: var(--font-secondary);
	color: var(--font-color-secondary);
}

@media (min-width: 768px) {


  .title {
    font-size: 2rem;
	text-align: center;
  }

  .text {
    font-size: 1.2rem;
  }
}
.image-container {
	height: 50%;
}

@media (min-width: 1400px) {
	.image-text {
		padding-top: 3vh;

	}
}

@media (max-width: 720px) {
	.image-text-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.image-text {
		padding:5%
	}
}

