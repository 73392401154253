/* Animation Panel for FlyTips */

.side_menu {
  position: fixed;
  top: 0;
  left: 0;
  transform: none !important;
  background-color: var(--accent-color);
  width: 10vw;
  z-index: 10; /* Ensure it's on top of the content */
  height: 100vh; /* Start covering the entire viewport height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  transition: background-color 1s ease;
}

.side_menu .animation_video {
  position: relative;
  top: calc(50vh - 10vw);
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(10vw - 20px);
  max-width: 10vw;
  height: 40%;
  max-height: 8vw;
}

.side_menu .animation_video img {
  width: 100%;
  height: 100%;
}

.side_menu .text {
  --scrolling: 0;
  position: relative;
  font-size: 5em;
  top: calc(50vh + 10vw - 5em);
}

.side_menu .text h1 {
  opacity: 1;
}

.side_menu .text h1 span {
  font-size: 1em;
  opacity: 1;
  scale: 0.8;
  color: whitesmoke;
}

.side_menu .bars {
  position: absolute;
  bottom: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 5vh;
  width: 5vw;
}

.side_menu .bars .bar {
  opacity: 1;
  background-color: whitesmoke;
  height: 25%;
  width: 100%;
}

.side_menu.passive {
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  align-items: baseline;
  height: 100%;
  width: 50vw;
  overflow: hidden;
}

.side_menu.passive .bars {
  left: 2.5vw;
}

.side_menu.passive .links {
  position: absolute;
  left: 10vw;
  max-width: 40vw;
  width: 40vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  opacity: 1 !important;
  background-color: var(--hover-bg-color);
}

.side_menu.passive .links .link {
  padding-left: 20px;
  width: 100%;
  font-size: 2.5em;
  font-family: "Arsenal SC", sans-serif;
  display: flex;
  height: 2.5em;
}

.side_menu.passive .links .link span {
  opacity: 0;
  position: relative;
  top: 0;
  right: 0.5em;
  display: block;
  width: 15px;
  margin-top: 35px;
  margin-left: 45px;
  color: var(--menu-font-color);
  height: 15px;
  border-top: 2px solid var(--menu-font-color);
  border-left: 2px solid var(--menu-font-color);
  transform: rotate(90deg);
}

.side_menu.passive .links .link span::after {
  content: "";
  display: block;
  width: 2px;
  height: 20px;
  position: relative;
  background-color: var(--menu-font-color);
  transform: rotate(-45deg) translate(7px, 5px);
}

.side_menu.passive .links .link h3 {
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
  font-weight: 600;
  font-family: "Titillium Web", sans-serif;
}

.side_menu.passive .links .link h3::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  top: 63%;
  left: 0;
  background-color: black;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.side_menu.passive .links .link h3:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.side_menu.passive .links .CGU {
  font-size: 2em;
  position: absolute;
  right: 4%;
  bottom: 1%;
  color: var(--menu-font-color);
}

.side_menu.passive .links .CGU a {
  color: inherit;
  text-decoration: none;
  transition: color 0.25s ease-out;
}

.side_menu.passive .links .CGU a:hover {
  color: var(--accent-color);
}

.side_menu.passive:hover {
  background-color: black;
}

.side_menu.black {
  background-color: #000;
}