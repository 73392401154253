.changing-font-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
	
  overflow: hidden;
  padding: 0 8vw;
  height: 40%;
}

.changing-font-header h1 {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--font-color-primary);
  font-family: "Roboto Flex", sans-serif;
}

.changing-font-header h2 {
  font-size: 1rem;
  font-weight: 400;
  color: var(--font-color-secondary);
  font-family: var(--font-secondary);
  line-height: 1.5rem;
}

@media (min-width: 768px) {
  .changing-font-header h1 {
    font-size: 3.5rem;
    margin-bottom: 0.5rem;
  }

  .changing-font-header h2 {
    font-size: 1.5rem;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (min-width: 1024px) {
  .changing-font-header {
    height: 40%;
  }

  .changing-font-header h1 {
    font-size: 4rem;
    margin-bottom: 0.5rem;
  }

  .changing-font-header h2 {
    font-size: 1.5rem;
    width: 60%;
    font-weight: 200;
    line-height: 2rem;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .changing-font-header h1 {
    font-size: 2.5rem;
    margin-bottom: 0rem;
  }

  .changing-font-header h2 {
    font-size: 1rem;
  }
}