.mobile-image-container {
    position: relative;
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-primary);
    overflow: hidden;
}

.mobile-image-container * {
    overflow: hidden;
}

.mobile-image-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 80%;
    overflow: hidden;
    background-color: var(--bg-secondary);
}

.mobile-image-boundary {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-image {
    height: 100%;
    object-fit: contain;
    overflow: hidden;
}

@media (orientation: landscape) {
    .mobile-image-container {
        height: 70vh;
    }
}