body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: fit-content;
}

@font-face {
  font-family: 'Roboto Flex';
  src: url('/public/media/fonts/Roboto-Flex.ttf') format('truetype-variations');
  font-weight: 100 900;
  font-stretch: 50% 125%;
}

:root {
  --element-position: 0;
/*   cursor: none; */
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  --bg-primary: #303030;
  --bg-secondary: #494b50;
   --bg-third: #d8ecec;

  --font-color-primary: #d8ecec;
  --font-color-secondary: #eefcfc;

  --font-primary: 'Titillium Web', sans-serif;
  --font-secondary: 'Roboto Flex', sans-serif;

  --accent-color: #738a90;
  --accent-color-hover: #d8ecec;

  --submit-color: #333333;
  --hover-bg-color: #bebebe;

  --menu-font-color: #000;
  --progress-bar-color: #eefcfc;

  --gradient-color-1: #6a11cb;
  --gradient-color-2: #2575fc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* body::-webkit-scrollbar {
  display: none;
} */
 
/*  body {
  -ms-overflow-style: none;
  scrollbar-width: none; 
} 
 */
