.image_rectangle {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.image_rectangle .row {
	width: 100%;
	min-height: 65vh;
	max-height: 80vh;
	display: flex;
	flex-direction: row;
	justify-content: center;
	box-sizing: border-box;
	overflow: hidden;
}

.image_rectangle .row .image_wrapper {
	padding: 2%;
	position: relative;
	border: 1px solid black;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
}

.image_rectangle .row .image_wrapper .wrapper {
	background-color: whitesmoke;
	width: 100%;
	height: 100%;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
}

.image_rectangle .row .image_wrapper .image_size {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.image_rectangle .row .image_wrapper .image_size img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.image_rectangle .row .image_wrapper .inside_text {
	width: 100%;
	position: absolute;
	top: 106%;
	left: 0%;
	padding: 0;
	padding-left: 6%;
	padding-right: 6%;
	margin: 0;
	height: 25%;
	transition: top 0.7s 0.1s ease;
	backdrop-filter: grayscale(100%) brightness(40%) blur(2px);
}

.image_rectangle .row .image_wrapper h4 {
	font-size: 1.2em;
	color: white;
	font-weight: 200;
	word-break: break-word;
	overflow: hidden;
	font-family: "Titillium Web", sans-serif;
}

.image_rectangle .row.reverse {
	flex-direction: row-reverse;
}

.image_rectangle .small {
	width: 35%;
}

.image_rectangle .big {
	width: 65%;
}

.image_rectangle .image_size:hover .inside_text {
	top: 75%;
}

/* Add responsive font sizes */
@media screen and (max-width: 1800px) {
	.image_rectangle .row .image_wrapper h4 {
		font-size: 1.1em;
	}
}

@media screen and (max-width: 1500px) {
	.image_rectangle .row .image_wrapper h4 {
		font-size: 1em;
	}
}

@media screen and (max-width: 1200px) {
	.image_rectangle .row .image_wrapper h4 {
		font-size: 0.9em;
	}
}

@media screen and (max-width: 992px) {
	.image_rectangle .row .image_wrapper h4 {
		font-size: 0.8em;
	}
}

@media screen and (max-width: 768px) {
	.image_rectangle .row .image_wrapper h4 {
		font-size: 0.7em;
	}
}

@media screen and (max-width: 576px) {
	.image_rectangle .row .image_wrapper h4 {
		font-size: 0.6em;
	}
}