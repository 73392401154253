.description-header {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.description-header h1 {
  font-size: 4rem;
  font-weight: 700;
  color: var(--font-color-primary);
  font-family: var(--font-primary);
}

.description-header p {
  font-size: 2rem;
  color: var(--font-color-secondary);
  font-family: var(--font-secondary);
}

@media (max-width: 1024px) {
  .description-header {
    width: auto;
    padding: 3px;
  }

  .description-header h1 {
    font-size: 2.5rem;
  }

  .description-header p {
    font-size: 1.4rem;
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .description-header h1 {
    font-size: 2.2rem;
  }

  .description-header p {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .description-header h1 {
    font-size: 1.8rem;
  }

  .description-header p {
    font-size: 1rem;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .description-header h1 {
    font-size: 2.5rem;
    margin-bottom: 0rem;
  }

  .description-header p {
    font-size: 1rem;
  }

  .row {
    height: 100%;
  }

  .block .block-content .block-title {
    height: 100%;
  }

  .block .block-content .block-description p {
    font-size: 0.8rem;
  }
}