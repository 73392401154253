.mobile-content {
    position: relative;
    padding: 3vw;
	background-color: var(--bg-secondary);
	overflow: hidden;

}

.mobile-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
	overflow: hidden;
}

.mobile-content-text {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: baseline;
    gap: 1rem;
}

.mobile-content-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--font-color-primary);
}

.mobile-content-subtitle {
    word-wrap: break-all;
    font-size: 1rem;
    font-weight: 400;
    color: var(--font-color-secondary);
}

.mobile-content-content {
    font-size: 1rem;
    font-weight: 400;
    color: var(--font-color-primary);
}

@media (orientation: landscape) {
	.mobile-content {
		padding: 3vw;
		min-height: 100vh;

		.mobile-content-title {
			font-size: 3rem;
		}

		.mobile-content-subtitle {
			font-size: 2rem;
		}

		.mobile-content-content {
			font-size: 2rem;
		}
	}
}