.desktop {
  width: 100vw;
  height: 100vh;
  overflow: auto; /* Allow scrolling */
  display: flex;
  
}

.desktop-content {
  width: 10vw;
  height: 100vh;
  background-color: red;
}

:root:has(.desktop) ::-webkit-scrollbar {
    width: 0;
    height: 0;
}

:root:has(.desktop) ::-webkit-scrollbar {
    display: none;
}
