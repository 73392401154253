.description {
	display: flex;
	flex-direction: column;
	justify-content: space-around;

	height: 100vh;
	padding: 2vw;
	background-color: var(--bg-primary);
}

.rows-container {
	display: flex;
	flex-direction: column;
	height: 80%;
}

.row-container {
	height: 50%;
}


@media (max-width: 768px) {
	.description {
		padding: 5vw;
	}

	.rows-container {
		height: 70%;
	}
}

@media (min-width: 1024px) {
	.description {
		flex-direction: row;
		align-items: center;
		padding: 3vw;
		
	}

	.rows-container {
		height: 70%;
		margin-top: 2vh;
		width: 50%;
	}
}

@media (max-width: 1024px) and (orientation: landscape) {
	.description {
		height: auto;
	}
}