.explaination_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  background-color: var(--bg-primary);
}

.explaination_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
}

.explaination_title h2 {
  font-size: 2rem;
  font-weight: 700;
  color: var(--font-color-primary);
  font-family: var(--font-primary);
}

.explaination_content {
  margin: 1rem;
  padding: 2rem;
  background-color: var(--bg-secondary);
  border: 2px solid black;
  box-shadow: inset 0px 0px 10px var(--bg-primary);
}

.explaination_content p {
  line-height: 1.5rem;
  color: var(--font-color-secondary);
  font-family: var(--font-secondary);
}

@media screen and (min-width: 768px) {
  .explaination_container {
    min-height: 30vh;
  }

  .explaination_content {
    max-width: 60%;
  }
}

@media screen and (min-width: 1024px) {
  .explaination_title h2 {
    font-size: 2.7rem;
  }

  .explaination_content {
    max-width: 90%;
  }

  .explaination_content p {
    font-size: 1.2rem;
    line-height: 2rem;
  }
}

@media screen and (min-width: 1440px) {
  .explaination_title h2 {
    font-size: 3.5rem;
  }

  .explaination_content {
    max-width: 80%;
  }

  .explaination_content p {
    font-size: 1.5rem;
    line-height: 2.5rem;
    font-weight: 200;
  }
}