.text_image_block {
	width: 100%;
	height: 1000px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.text_image_block .image_wrapper {
	width: 30%;
	height: 70%;
}

.text_image_block .image_wrapper img {
	width: 100%;
	height: 100%;
	--spread: 0px;
	object-fit: contain;
	filter: drop-shadow(0px 0px var(--spread) rgb(160, 160, 160));
}

.text_image_block .text {
	width: 40%;
	height: 80%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.text_image_block .text h1 {
	width: 100%;
	font-size: 3em;
	letter-spacing: 0.2em;
	text-transform: uppercase;
	font-family: "Titillium Web", sans-serif;
	font-weight: 500;
}

.text_image_block .text h3 {
	font-family: "Roboto Flex", sans-serif;
	line-height: 1.3em;
	font-size: 1.1em;
	font-weight: 200;
}

/* Laptop (max-width: 1024px) */
@media (max-width: 1024px) {
	.text_image_block {
		height: auto;
		max-height: none;
		padding: 40px 0;
	}

	.text_image_block .image_wrapper {
		width: 40%;
		height: auto;
	}

	.text_image_block .text {
		width: 50%;
	}

	.text_image_block .text h1 {
		font-size: 2.5em;
	}

	.text_image_block .text h3 {
		font-size: 1em;
	}

	.behind_animation_height {
		height: auto;
	}
}

/* Tablet (max-width: 768px) */
@media screen and (max-width: 768px) {
	.text_image_block {
		flex-direction: column;
		padding: 30px 0;
	}

	.text_image_block .image_wrapper {
		width: 80%;
		margin-bottom: 30px;
	}

	.text_image_block .text {
		width: 80%;
		text-align: center;
	}

	.text_image_block .text h1 {
		font-size: 2em;
	}
}

/* Mobile (max-width: 480px) */
@media screen and (max-width: 480px) {
	.text_image_block {
		padding: 20px 0;
	}

	.text_image_block .image_wrapper {
		width: 90%;
	}

	.text_image_block .text {
		width: 90%;
	}

	.text_image_block .text h1 {
		font-size: 1.5em;
		letter-spacing: 0.1em;
	}

	.text_image_block .text h3 {
		font-size: 0.9em;
	}
}

