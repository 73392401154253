.annexe_page {
	background-color: var(--bg-primary);
	width: 90vw;
}

.annexe_page.mobile {
	width: 100%;
}

.annexe_page .slider {
	position: fixed;
	top: 0px;
	right: 0;
	width: 10px;
	z-index: 1000;
	background-color: var(--progress-bar-color);
	transition: width 0.1s ease;
}