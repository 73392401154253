.explainations_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}




@media screen and (min-width: 1024px) {
  .explainations_wrapper {
    flex-direction: row;
  }
}
