.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60%;
  padding: 20px;
  transition: background-image 0.3s ease;
}

.content-container h1,
.content-container p {
  font-family: "Roboto Flex", sans-serif;
  font-optical-sizing: auto;
  font-size: 2rem;
}

.text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40%;
}

.sliders {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 7vh;
  width: 100%;
  height: 60%;
  min-width: 300px;
}

.slider-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.slider-container span {
  font-size: 0.7rem;
  color: var(--font-color-secondary);
  width: 50px;
}

input[type="range"] {
  -webkit-appearance: none;
  width: calc(100% - 120px);
  margin: 0 10px;
  background: transparent;
  height: 10px;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: #888888;
  border-radius: 2px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 32px;
  width: 32px;
  border-radius: 10px;
  background: #ffffff;
  cursor: pointer;
  margin-top: -12px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: #888888;
  border-radius: 2px;
}

input[type="range"]::-moz-range-thumb {
  height: 32px;
  width: 32px;
  border-radius: 10px;
  background: #ffffff;
  cursor: pointer;
  border: none;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #888888;
  border-radius: 2px;
}

input[type="range"]::-ms-fill-upper {
  background: #888888;
  border-radius: 2px;
}

input[type="range"]::-ms-thumb {
  height: 16px;
  width: 16px;
  border-radius: 0;
  background: #ffffff;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

@media (min-width: 766px) and (orientation: landscape) {
  .sliders span {
    width: 100px;
    font-size: 1.2rem;
  }

  .slider-container {
    gap: 10vh;
  }
}

@media (min-width: 1024px) {
  .sliders {
    gap: 8vh;
    width: calc(100% - 2 * 8vw);
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .sliders {
    gap: 10vh;
  }
}