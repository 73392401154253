.contact-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 20px;
  box-sizing: border-box;
  background-color: var(--bg-secondary);
  overflow: hidden;
  max-height: calc(100vh - 60px);

}
.contact-form-container.vertical-layout {
  flex-direction: column;
  align-items: stretch;
  height: auto;
  align-items: center;
}
.contact-form-header {
  margin-bottom: 4vh;
}

.contact-form-header h2 {
  font-size: 4vh;
  color: var(--font-color-primary);
  margin-bottom: 2vh;
  font-family: var(--font-primary);
  font-weight: 500;
}

.contact-form-header p {
  font-size: 2vh;
  color: var(--font-color-secondary);
  font-family: var(--font-secondary);
  font-weight: 200;
}

.contact-form {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 2vh;

}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-size: 2.5vh;
  color: var(--font-color-secondary);
  margin-bottom: 1vh;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 2vh;
  border: 1px solid #888888;
  border-radius: 4px;
  font-family: "Roboto Flex", sans-serif;
  font-size: 2.5vh;
  background-color: transparent;
  color: var(--font-color-primary);
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--accent-color);
}

.form-group textarea {
  resize: vertical;
  min-height: 15vh;
}

.submit-button {
  align-self: flex-start;
  padding: 2vh 4vh;
  background-color: var(--submit-color);
  color: white;
  border: none;
  border-radius: 4px;
  font-family: "Roboto Flex", sans-serif;
  font-size: 2.5vh;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: var(--accent-color-hover);
}

@media (min-width: 768px) and (orientation: landscape) {
	.contact-form-container{
		position: relative;
		backdrop-filter: blur(10px) brightness(1.5);
    margin: 5vh;
    border-radius: 4vh;
		background-color: rgba(44, 44, 44, 0.5);
		border-radius: 4vh;
		overflow: hidden;
    width: calc(100% - 10vh);
    height: calc(100% - 10vh);
    max-height: none;
	}
  
  .contact-form {
		max-width: 600px;
		background-color: var(--bg-secondary);
		padding: 4vh;
		border-radius: 4vh;
		box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  }

  .form-group label {
    font-size: 2vh;
  }

  .form-group input,
  .form-group textarea,
  .submit-button {
    font-size: 2vh;
  }
}

@media (min-width: 1024px) and (orientation: landscape) {
  .contact-form {
    max-width: 700px;
  }

  .form-group label {
    font-size: 1.8vh;
  }

  .form-group input,
  .form-group textarea,
  .submit-button {
    font-size: 1.8vh;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .contact-form-container {
    width: 100vw;
    min-height: 100vh;
    margin: 0;
    border-radius: 0;
    backdrop-filter: none;
    background-color: var(--bg-secondary);
  }

  .contact-form {
    max-width: 100%;
    background-color: transparent;
    padding: 4vh;
    border-radius: 0;
    box-shadow: none;
  }

  .contact-form-header h2 {
    font-size: 3rem;
    color: var(--font-color-primary);
  }

  .contact-form-header p {
    font-size: 1.8rem;
    color: var(--font-color-secondary);
  }

  .form-group label {
    font-size: 2.5vh;
  }

  .form-group input,
  .form-group textarea,
  .submit-button {
    font-size: 2.5vh;
  }
}