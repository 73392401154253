/*
DO NOT USE THIS COMPONENT, IT'S MODIFIED FOR 414DEV. USE THE ONE FROM FLYTIPS WITH THE SAME NAME
*/

.ChangingImageAndBlackText {
	box-sizing: border-box;
	position: relative;

	--left: 0%;
	--right: 100%;
}

.ChangingImageAndBlackText .row {
	display: flex;
	flex-direction: row;
}

.ChangingImageAndBlackText .top {
	max-height: 70vh;
	height: 900px;
	width: 90vw;
}

.ChangingImageAndBlackText .top .img_wrapper {
	padding: 24px;
	height: 100%;
	width: 60%;
	position: relative;
	overflow: hidden;
}

.ChangingImageAndBlackText .top .img_wrapper img {
	padding: 24px;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
	opacity: 1;
	transform: scale(1.1);
	transition: opacity 2s ease-in-out, transform 2s ease;
}

.ChangingImageAndBlackText .top .img_wrapper img.active {
	opacity: 1;
	transform: scale(1);
}

.ChangingImageAndBlackText .box {
	width: 40%;
	color: var(--font-color-primary);
	font-size: 1.3em;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-family: "Titillium Web", sans-serif;
}

.ChangingImageAndBlackText .box h1 {
	position: inherit;
	padding-left: 22px;
	font-size: 4em;
	font-weight: 700;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	font-family: var(--font-primary);
}

.ChangingImageAndBlackText .box h3 {
	font-family: var(--font-secondary);
	position: relative;
	padding-left: 12px;
	padding-right: 12px;
	font-weight: 200;
	font-size: 2rem;
	line-height: 1.5em;
	color: var(--font-color-secondary);
}

.ChangingImageAndBlackText::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	border-radius: 10px;
	height: 1px;
	background: linear-gradient(
		to right,
		transparent 0%,
		transparent var(--left),
		var(--font-color-primary) var(--left),
		var(--font-color-primary) var(--right),
		transparent var(--right),
		transparent 100%
	);
}

/* Add responsive font sizes */
@media (max-width: 1200px) {
	.ChangingImageAndBlackText .box {
		font-size: 1.1em;
	}

	.ChangingImageAndBlackText .box h1 {
		font-size: 3.5em;
	}

	.ChangingImageAndBlackText .box h3 {
		font-size: 1.8rem;
	}
}

@media (max-width: 992px) {
	.ChangingImageAndBlackText .box {
		font-size: 1em;
	}

	.ChangingImageAndBlackText .box h1 {
		font-size: 3em;
	}

	.ChangingImageAndBlackText .box h3 {
		font-size: 1.6rem;
	}
}

@media (max-width: 768px) {
	.ChangingImageAndBlackText .box {
		font-size: 0.9em;
	}

	.ChangingImageAndBlackText .box h1 {
		font-size: 2.5em;
	}

	.ChangingImageAndBlackText .box h3 {
		font-size: 1.4rem;
	}
}

@media (max-width: 576px) {
	.ChangingImageAndBlackText .box {
		font-size: 0.8em;
	}

	.ChangingImageAndBlackText .box h1 {
		font-size: 2em;
	}

	.ChangingImageAndBlackText .box h3 {
		font-size: 1.2rem;
	}
}