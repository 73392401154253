.mobile_menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--accent-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
}

.mobile_menu_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  height: 60px;
}

.mobile_logo {
  height: 40px;
  width: auto;
}

.mobile_toggle {
  cursor: pointer;
}

.mobile_toggle .bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 5px 0;
  transition: 0.3s;
}

.mobile_links {
  padding: 10px 20px;
}

.mobile_link {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.mobile_link:last-child {
  border-bottom: none;
}

.mobile_link h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.mobile_link a {
  text-decoration: none;
  color: inherit;
}
